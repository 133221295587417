import React, { Fragment } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import Add from '@material-ui/icons/Add';
//import RemoveCircle from '@material-ui/icons/RemoveCircle';


const SpeakerRow = (props) => {
  const members = props.members;
  // Add a blank option to start of members
  var members_data_source = [{ID: "", Status: "", Firstname: "", Lastname: "", FullName: ""}].concat(members);
  return (
    <Fragment>
      <tr>
        <td className="e-textlabel"></td>
        <td>
          <DropDownListComponent 
            id="SpeakerName" 
            placeholder='Speaker Name' 
            data-name={"SpeakerName_" + props.id} 
            className="e-field" 
            style={{ width: '100%' }} 
            dataSource={members_data_source} 
            fields={{text: 'FullName', value: 'ID'}} 
            sortOrder="Ascending"
            value={props.name || null}
          ></DropDownListComponent>
        </td>
        <td>
          <DropDownListComponent 
            id="SpeakerType" 
            placeholder='Speaker Type' 
            data-name={"SpeakerType_" + props.id} 
            className="e-field" 
            style={{ width: '100%' }} 
            dataSource={['', 'Invited diplomate', 'Overseas diplomate', 'Invited speaker']} 
            value={props.type || null}
          ></DropDownListComponent>
        </td>
        <td>
          <TextBoxComponent 
            id="SpeakerNotes" 
            placeholder='Speaker Notes' 
            className="e-field e-input speaker-notes" 
            type="text" 
            name={"SpeakerNotes_" + props.id} 
            value={props.notes}
          />
        </td>
      </tr>
    </Fragment>
  )
}

const AddSpeakerRow = (props) => {
  return (
    <Fragment>
      <tr>
        <td className="e-textlabel"></td>
        <td colSpan="1" ><p className="add-speaker" onClick={() => props.onClick()}><Add color="inherit" /> Add Speaker</p></td>
      </tr>
    </Fragment>
  )
}

/*const RemoveSpeaker = (props) => {
  return (
    <Fragment>
        <span className="remove-speaker" onClick={() => props.onClick(props.id)}><RemoveCircle color="inherit" /></span>
    </Fragment>
  )
}*/

export default class Speakers extends React.Component {
    constructor(props){
      super(props);
      const speakers = [];
      let speakerExists = false;
      if(props.SpeakerName_0 !== undefined){
        speakerExists = true;
        let x = 0;
        while(speakerExists === true){
          let speakerPropertyName = 'SpeakerName_' + x;
          let speakerPropertyType = 'SpeakerType_' + x;
          let speakerPropertyNotes = 'SpeakerNotes_' + x;
          let speaker = {};
          if(props[speakerPropertyName] !== undefined){
            speaker.name = props[speakerPropertyName];
            if(props[speakerPropertyType] !== undefined){
              speaker.type = props[speakerPropertyType];
            }else{
              speaker.type = null;
            }
            if(props[speakerPropertyNotes] !== undefined){
              speaker.notes = props[speakerPropertyNotes];
            }else{
              speaker.notes = null;
            }
            speakers.push(speaker);
            x++;
          }else{
            speakerExists = false;
          }
        }
      }
      if(speakers.length === 0){
        speakers.push({name: '', type: null, notes: null});
      }
      this.state = {
          speakers: speakers,
          members: props.members || []
      }
    }

    addSpeakerRow = (props) => {
      const speakers = this.state.speakers;
      this.setState({
        speakers: speakers.concat([
          {
            name: '',
            type: '',
            notes: ''
          }
        ])
      });
    }

    /*removeSpeakerRow = (id) => {
      const speakers = this.state.speakers;
      if(id && id !== 0){
        speakers.splice(id, 1);
        this.setState({
          speakers: speakers
        });
      }
    }*/


    render(){
      const speakers = this.state.speakers;
      const members = this.state.members
      return (
        <Fragment>
          {speakers && speakers.map((speaker, i) => {
          return <SpeakerRow key={i} name={speaker.name} type={speaker.type} notes={speaker.notes} id={i} members={members}/>//removeSpeaker={this.removeSpeakerRow}
          })}
          <AddSpeakerRow onClick={this.addSpeakerRow} />
        </Fragment>
      );
    }
}