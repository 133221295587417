import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { SimpleForm, TextInput, DateInput, showNotification, Toolbar, SaveButton} from 'react-admin';
import CheckActiveSession from '../api/CheckActiveSession'; 
import { beforeLocationChange } from 'ra-core';

const StreamToolbar = props => (
    <Toolbar {...props} className="eventToolbar">
        <SaveButton
            label="Save"
            redirect={false}
            submitOnEnter={true}
        />
    </Toolbar>
);

class StreamForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            event: null,
            slots: [],
            streamData: [],
        }
        this.eventID = props.match.params.id || null;
        this.abortController = new AbortController();
    }

    componentDidMount(){
        const sid = localStorage.getItem('sid');
        const hostname = localStorage.getItem('hostname');
        CheckActiveSession(sid).then(res => {
            if(res.status !== true){
                window.location.href="https://" + hostname + "/ecvs-admin/";
            }
        });
        if(this.eventID !== null){
            this.getEventSlots(this.eventID);
        }
    }


    getEventSlots(eventID){
      const sid = localStorage.getItem('sid');
      const hostname = localStorage.getItem('hostname');
      return fetch('https://' + hostname + '/api/getSlots.php?s=' + sid + '&event_id=' + eventID, {
        method: 'GET',
        signal: this.abortController.signal,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.ok){
          return response.json();
        } else{
          console.log('Unable to fetch slots');
        }
      }).then(res => {
        let slots = this.parseJSONStringArray(res.result);
        return slots;
      }).then(slots => {
        this.setState({slots: slots});
        //let locationData = this.getLocations(slots);
        let streamData = this.getStreams(slots);
        this.setState({streamData: streamData.sort(this.sortAlpha)});
      }).catch(err => console.log(err.message));
    }


    getStreams(slots){
      if(slots !== null){
        const streams = {};
        const streamData = [];
        let streamCounter = 1;
        for (let x = 0; x < slots.length; x++ ){
          if(slots[x].Stream && !streams[slots[x].Stream]){
            streams[slots[x].Stream] = slots[x].Stream;
            streamData.push({text: slots[x].Stream, id: streamCounter});
            streamCounter++;
          }
        }
        return streamData;
      }else{
        return [];
      }
    }

    parseJSONStringArray(JSONStringArray){
        return (JSONStringArray !== null && JSONStringArray !== undefined ? JSONStringArray.map((JSONString) => JSON.parse(JSONString)) : null);
    }

    onSubmit = (props) => {
        const { showNotification } = this.props;
        const sid = localStorage.getItem('sid');
        const hostname = localStorage.getItem('hostname');
        Object.keys(props).forEach((stream) => {
          let stream_id = stream.split('_')[1];
          let new_stream_name = props[stream];

          let changed = this.state.streamData.find((stream) => {
            if (stream.id == stream_id) {
              if (stream.text !== new_stream_name) {
                console.log(stream.id, stream_id, stream.text, new_stream_name)
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          })

          if (changed) {
            let url = 'https://' + hostname + '/api/updateStreams.php?s=' + sid + '&event_id=' + this.eventID;
            let successMessage = 'Streams updated';
            let errorMessage = 'Unable to update streams';
            var body = {
              original: changed.text,
              new: new_stream_name,
            }
            fetch(url, {
              method: 'POST',
              headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
              body: JSON.stringify(body)
            }).then((response) => {
                if(response.ok){
                    return response.json();
                } else{
                    console.log(errorMessage);
                    return false;
                }
            }).then((res) => {
                if(res.status !== false){
                    showNotification(successMessage, 'info');
                    return true;
                } else{
                    showNotification(errorMessage, 'warning');
                    return false;
                }
            }).catch((e) => {
                //console.log('Exception caught: ' + e);
                showNotification(errorMessage, 'warning');
            });
          }
        })

        this.props.history.push("/event/" + this.eventID);
        
    }

    validateEvent = (props) => {
        const errors = {};
        Object.keys(props).forEach((stream) => {
          if (!props[stream]) {
            errors[stream] = ['Stream name cannot be empty']
          }
        })
        return errors;
    }

    sortAlpha( a, b ) {
      if ( a.text < b.text ){
        return -1;
      }
      if ( a.text > b.text ){
        return 1;
      }
      return 0;
    }

    render(){
      return(
        <SimpleForm className="edit_steams_form" save={this.onSubmit} validate={this.validateEvent} toolbar={<StreamToolbar eventid={this.eventID}/>}>
          {this.state.streamData.length > 0 && this.state.streamData.map((stream, i) => {
            return (
              <div className="edit_steams_form_group" key={i}>
                <label style={{marginRight: '5px'}}>{i+1}.</label>
                <TextInput className="edit_steams_input" label={false} source={"stream_" + stream.id} defaultValue={stream.text}/>
              </div>
            )
          })}
        </SimpleForm>
      );
    }


}

export default connect(null, {
    showNotification
})(withRouter(StreamForm));