import React from 'react';
import { Route } from 'react-router-dom';
import EventScheduler from './events/EventScheduler';
import Dashboard from './Dashboard';
import { EventsEdit } from './events/EventsEdit';
import { StreamsEdit } from './streams/StreamsEdit';

export default [
  <Route path="/authenticate/:sid" component={Dashboard} />,
  <Route path="/event/:id" component={EventScheduler} />,
  <Route exact path="/events/edit/:id" component={EventsEdit} />,
  <Route exact path="/events/edit/streams/:id" component={StreamsEdit} />
];