import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { showNotification } from 'react-admin';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

class DeleteEventButton extends Component {
    constructor(props){
        super(props);
        this.state = {
            isVisible: false
        }
        this.dialogButtons = [{
            buttonModel: {
                content: 'DELETE',
                cssClass: 'e-flat',
                isPrimary: true,
            },
            'click': () => {
                this.setState({ isVisible: false });
                this.deleteEvent();
            }
        },
        {
            buttonModel: {
                content: 'Cancel',
                cssClass: 'e-flat'
            },
            'click': () => {
                this.setState({ isVisible: false });
            }
        }];
        if(props.eventid !== undefined && props.eventid !== null){
            this.eventID = props.eventid;
        }else{
            this.eventID = null;
        }
    }
    onClickHandler = (props) => {
        this.setState({ isVisible: true });
    }
    dialogClose = (props) => {
        this.setState({ isVisible: false });
    }
    deleteEvent = (props) => {
        const sid = localStorage.getItem('sid');
        const hostname = localStorage.getItem('hostname');
        const { showNotification } = this.props;
        if(this.eventID !== null){
            fetch('https://' + hostname + '/api/deleteEvent.php?s=' + sid + '&event_id=' + this.eventID, {
                method: 'DELETE',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            }).then((response) => {
                if(response.ok){
                    return response.json();
                } else{
                    console.log('Unable to fetch slots');
                }
            }).then((res) => {
                if(res.status !== false){
                    showNotification('Event deleted', 'info');
                    this.props.history.push("/");
                } else{
                    showNotification('Unable to delete event', 'warning');
                }
            }).catch((e) => {
                console.log('Exception caught: ' + e);
                showNotification('Unable to delete event', 'warning');
            });
        }
    }
    render(){
        return(
            <Fragment>
                <Button 
                    onClick={this.onClickHandler}
                    className="delete_button"
                ><DeleteIcon /> {this.props.label}</Button>
                <DialogComponent 
                    width='300px' 
                    header='Delete Event'
                    content='Are you sure you want to delete this event?' 
                    visible={this.state.isVisible} 
                    close={this.dialogClose} 
                    isModal={true}
                    showCloseIcon={true}
                    buttons={this.dialogButtons}
                />
            </Fragment>
        );
    }
}

export default connect(null, {
    showNotification
})(withRouter(DeleteEventButton));