import React from 'react';
import { CardActions, CreateButton, RefreshButton, List } from 'react-admin';
import { EventGrid } from './EventGrid';

const EventActions = ({
  basePath = "/events/create",
}) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
);

export const Events = (props) => (
  <List
    {...props}
    actions={<EventActions />}
    title="Events"
  >
    <EventGrid />
  </List>
);