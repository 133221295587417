import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { SimpleForm, TextInput, DateInput, showNotification, Toolbar, SaveButton} from 'react-admin';
import CheckActiveSession from '../api/CheckActiveSession'; 
import DeleteEventButton from './DeleteEventButton';

const EventToolbar = props => (
    <Toolbar {...props} className="eventToolbar">
        <SaveButton
            label="Save"
            redirect={false}
            submitOnEnter={true}
        />
        {props.eventid !== null && 
            <DeleteEventButton
                label="Delete"
                {...props}
            />
        }
    </Toolbar>
);

class EventForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            event: null
        }
        this.eventID = props.match.params.id || null;
    }

    componentDidMount(){
        const sid = localStorage.getItem('sid');
        const hostname = localStorage.getItem('hostname');
        CheckActiveSession(sid).then(res => {
            if(res.status !== true){
                window.location.href="https://" + hostname + "/ecvs-admin/";
            }
        });
        if(this.eventID !== null){
            return fetch('https://' + hostname + '/api/getEvents.php?s=' + sid + '&event_id=' + this.eventID, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json'
                }
            }).then(response => {
                if(response.ok){
                    return response.json();
                } else{
                    console.log('Unable to fetch event');
                }
            }).then(res => {
                let event = this.parseJSONStringArray(res.result);
                return event;
            }).then(event => {
                this.setState({event: event[0]});
            }).catch(err => console.log(err.message));
        }
    }
    parseJSONStringArray(JSONStringArray){
        return (JSONStringArray !== null && JSONStringArray !== undefined ? JSONStringArray.map((JSONString) => JSON.parse(JSONString)) : null);
    }
    onSubmit = (props) => {
        const { showNotification } = this.props;
        const sid = localStorage.getItem('sid');
        const hostname = localStorage.getItem('hostname');
        let url = '';
        let successMessage = '';
        let errorMessage = '';
        if(this.eventID !== null){
            //hit the update script
            url = 'https://' + hostname + '/api/createEvent.php?s=' + sid + '&event_id=' + this.eventID;
            successMessage = 'Event updated';
            errorMessage = 'Unable to update event';
        }else{
            //hit the create script
            url = 'https://' + hostname + '/api/createEvent.php?s=' + sid
            successMessage = 'Event created';
            errorMessage = 'Unable to create event';
        }
        fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(props)
        }).then((response) => {
            if(response.ok){
                return response.json();
            } else{
                //console.log('Unable to fetch slots');
            }
        }).then((res) => {
            if(res.status !== false){
                showNotification(successMessage, 'info');
                if(res.result.event_id){
                    this.props.history.push("/event/" + res.result.event_id);
                }
            } else{
                showNotification(errorMessage, 'warning');
            }
        }).catch((e) => {
            //console.log('Exception caught: ' + e);
            showNotification(errorMessage, 'warning');
        });
    }

    validateEvent = (props) => {
        const errors = {};
        if(!props.location){
            errors.location = ['A location is required'];
        }
        if(!props.start_date){
            errors.start_date = ['A start date is required'];
        }
        if(!props.end_date){
            errors.end_date = ['A end date is required'];
        }
        if(props.start_date && props.end_date){
            let start_date = new Date(props.start_date);
            let end_date = new Date(props.end_date);
            if(end_date < start_date){
                errors.end_date = ['The end cannot be before the start'];
            }
        }
        return errors;
    }

    render(){
        const event = this.state.event;
        let location = '', startDate = new Date(), endDate = new Date();
        if(event !== null){
            location = event.Location;
            startDate = event.StartDate;
            endDate = event.EndDate;
        }
        return(
            <SimpleForm save={this.onSubmit} validate={this.validateEvent} toolbar={<EventToolbar eventid={this.eventID}/>}>
                <TextInput label="Location" source="location" defaultValue={location}/>
                <DateInput label="Start date" source="start_date" defaultValue={startDate}/>
                <DateInput label="End date" source="end_date" defaultValue={endDate}/>
            </SimpleForm>
        );
    }


}

export default connect(null, {
    showNotification
})(withRouter(EventForm));