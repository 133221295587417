const CheckActiveSession = (sessionID) => {
  if(sessionID === undefined || sessionID === null){
    return false;
  }else{
    const hostname = localStorage.getItem('hostname');
    return fetch('https://' + hostname + '/api/checkActiveSession.php?s=' + sessionID, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log('Unable to check session');
      }
    }).then(res => {
      return res;
    }).catch(err => console.log(err.message));
  }
}

export default CheckActiveSession;