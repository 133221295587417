import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CheckActiveSession from './api/CheckActiveSession';


export default class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      activeSession: 'No'
    }
    var hostname = window.location.hostname;
    if(hostname !== 'planner.ecvs.org'){
      hostname = 'dev-ecvs.ambrosefox.com';
    }else{
      hostname = 'ecvs.org';
    }
    localStorage.setItem('hostname', hostname);
    if(props.match.params.sid !== undefined && props.match.params.sid !== null){
      const sid = props.match.params.sid;
      localStorage.setItem('sid', sid);
      props.history.replace('/');
    }
    if(!localStorage.getItem('sid')){
      window.location.href="https://" + hostname + "/ecvs-admin/";
    }
  }

  componentDidMount(){
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    if(sid){
      CheckActiveSession(sid).then(res => {
        if(res){
          if(res.status === true){
            this.setState({
              activeSession: 'Yes'
            })
            return;
          }else{
            this.setState({
              activeSession: 'No'
            })
            window.location.href="https://" + hostname + "/ecvs-admin/";
            return;
          }
        }else{
          this.setState({
            activeSession: 'No'
          })
          window.location.href="https://" + hostname + "/ecvs-admin/";
          return;
        }
      });
    }
  }

  render(){
    //const sid = localStorage.getItem('sid');
    return(
      <Card>
        <CardHeader title="Getting Started" />
        <CardContent>
          <p><u>Events</u></p>
          <p>Events can be created by clicking on the 'Create Event' link in the left hand menu. To view an event schedule, select it from the left hand menu.</p>
          <p>To edit or delete an event, view the event and click the 'Edit Event' button.</p>
          <br />
          <p><u>Slots</u></p>
          <p>When starting a new event, at least 1 'location' is required before any slots can be put into the schedule.</p>
          <p>Locations can be created using the locations bar at the top of an event schedule.</p>
          <CardMedia className='getting-started-gif' component='img' src='/images/location_create.gif'/>
          <p></p>
          <p>The locations bar can also be used to filter the current view, by selecting which locations should be shown on the schedule.</p>
          <CardMedia className='getting-started-gif' component='img' src='/images/location_filter.gif'/>
          <p></p>
          <p>Once a location has been added slots can be created. View the event schedule and single or double click an empty square.</p>
          <CardMedia className='getting-started-gif' component='img' src='/images/create_slot.gif'/>
          <p></p>
          <p>NEW: Slots can be created over a range by clicking and dragging on the cells, then pressing ENTER on the keyboard.</p>
          <CardMedia className='getting-started-gif' component='img' src='/images/create_slot_range.gif'/>
          <p></p>
          <p>The start and end time of the slot can be adjusted by dragging the edges of the slot box. Or by dragging and dropping the whole slot.</p>
          <CardMedia className='getting-started-gif' component='img' src='/images/drag_drop_slot.gif'/>
          <p></p>
          <p>Finally, slots can be moved between locations by dragging them.</p>
          <CardMedia className='getting-started-gif' component='img' src='/images/drag_drop_slot_location.gif'/>
          <p></p>
          <h4 style={{marginTop: '30px'}}>Help</h4>
          <p>
            Performance Issues: If you are experiencing any performace issues when viewing a schedule on a large screen, turning off hardware acceleration should help. Follow the guide below for the browser you are using:<br />
            <ul>
              <li><a href="https://support.mozilla.org/en-US/kb/hardware-acceleration-and-windowblinds-crash" target="_blank" rel="noopener noreferrer">FireFox</a></li>
              <li><a href="https://www.howtogeek.com/412738/how-to-turn-hardware-acceleration-on-and-off-in-chrome/" target="_blank" rel="noopener noreferrer">Chrome</a></li>
            </ul>
          </p>
        </CardContent>
        {/*<CardContent>Session ID: {sid}</CardContent>
        <CardContent>Session Active: {this.state.activeSession}</CardContent>*/}
      </Card>
    )
  }
}
