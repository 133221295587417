import React from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import Dashboard from './Dashboard';
import PlannerLayout from './layout/PlannerLayout';
import { Events } from './events/Events';
import { EventsCreate } from './events/EventsCreate';
import customRoutes from './customRoutes';
import './styles/material.css';
import './styles/custom.css';


const dataProvider = jsonServerProvider('https://dev-ecvs.ambrosefox.com');

const App = () => (
  <Admin 
    title="ECVS Planner" 
    appLayout={PlannerLayout} 
    dashboard={Dashboard} 
    dataProvider={dataProvider}
    customRoutes={customRoutes}
  >
    <Resource name="events" list={Events} create={EventsCreate}/>
  </Admin>
);

export default App;
