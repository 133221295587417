import React, { Fragment } from 'react';
import Scheduler from '../scheduler/Scheduler';
import CheckActiveSession from '../api/CheckActiveSession';

export default class EventScheduler extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      id: this.props.match.params.id,
      event: {},
      slots: [],
      //locationData: [],
      streamData: [],
      invited_speaker_submissions: [],
      abstract_submissions: [],
      members: []
    };
    this.colours = [
      '#006600',
      '#0036D9',
      '#8C008C',
      '#B25900',
      '#575748',
      '#408C40',
      '#4068E3',
      '#A940A9',
      '#C58340',
      '#818176'
    ];
    this.abortController = new AbortController();
    this._is_Mounted = false;
  }
  getEvent(eventID){
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    return fetch('https://' + hostname + '/api/getEvents.php?s=' + sid + '&event_id=' + eventID, {
      method: 'GET',
      signal: this.abortController.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log('Unable to fetch event');
      }
    }).then(res => {
      let event = this.parseJSONStringArray(res.result);
      return event;
    }).then(event => {
      this.setState({event: event[0]});
      return event[0];
    }).catch(err => console.log(err.message));
  }
  getEventSlots(eventID){
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    return fetch('https://' + hostname + '/api/getSlots.php?s=' + sid + '&event_id=' + eventID, {
      method: 'GET',
      signal: this.abortController.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log('Unable to fetch slots');
      }
    }).then(res => {
      let slots = this.parseJSONStringArray(res.result);
      return slots;
    }).then(slots => {
      this.setState({slots: slots});
      //let locationData = this.getLocations(slots);
      let streamData = this.getStreams(slots);
      this.setState({streamData: streamData});
    }).catch(err => console.log(err.message));
  }
  getAbstractSubmissions(year){
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    return fetch('https://' + hostname + '/api/getAbstractSubmissions.php?s=' + sid + '&meeting_year=' + year, {
      method: 'GET',
      signal: this.abortController.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log('Unable to fetch abstract submissions');
      }
    }).then(res => {
      let abstractSubmissions = this.parseJSONStringArray(res.result);
      return abstractSubmissions;
    }).then(abstractSubmissions => {
      this.setState({abstract_submissions: abstractSubmissions});
    }).catch(err => console.log(err.message));
  }
  getInvitedSpeakerSubmissions(year){
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    return fetch('https://' + hostname + '/api/getInvitedSpeakerSubmissions.php?s=' + sid + '&meeting_year=' + year, {
      method: 'GET',
      signal: this.abortController.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log('Unable to fetch invited speaker submissions');
      }
    }).then(res => {
      let invitedSpeakerSubmissions = this.parseJSONStringArray(res.result);
      return invitedSpeakerSubmissions;
    }).then(invitedSpeakerSubmissions => {
      this.setState({invited_speaker_submissions: invitedSpeakerSubmissions});
    }).catch(err => console.log(err.message));
  }
  getMembers(){
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    return fetch('https://' + hostname + '/api/getMembers.php?s=' + sid, {
      method: 'GET',
      signal: this.abortController.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.ok){
        return response.json();
      } else{
        console.log('Unable to fetch invited speaker submissions');
      }
    }).then(res => {
      let members = this.parseJSONStringArray(res.result);
      return members;
    }).then(members => {
      this.setState({members: members});
    }).catch(err => console.log(err.message));
  }
  /*getLocations(slots){
    if(slots !== null){
      const locations = {};
      const locationData = [];
      let slot;
      for(slot of slots){
        if(slot.Location.length !== 0 && !locations[slot.Location]){
          locations[slot.Location] = slot.Location;
          locationData.push({text: slot.Location, id: slot.LocationID, color: this.colours[slot.LocationID]});
        }
      }
      return locationData;
    }else{
      return [];
    }
  }*/
  getStreams(slots){
    if(slots !== null){
      const streams = {};
      const streamData = [];
      let slot;
      for(slot of slots){
        if(slot.Stream && !streams[slot.Stream]){
          streams[slot.Stream] = slot.Stream;
          streamData.push({text: slot.Stream, id: slot.StreamID, color: this.colours[slot.StreamID]});
        }
      }
      return streamData;
    }else{
      return [];
    }
  }
  parseJSONStringArray(JSONStringArray){
      return (JSONStringArray !== null && JSONStringArray !== undefined ? JSONStringArray.map((JSONString) => JSON.parse(JSONString)) : null);
  }
  async getEventData(eventId){
    let startYear = new Date().getFullYear();
    await this.getEvent(eventId).then(event => {
      if(event !== undefined){
        startYear = new Date(Date.parse(event.StartDate.replace('-','/','g'))).getFullYear();
      }
    }); // We have to wait for this fetch as it will return the start dates we must pass into the abstracts
    if(startYear !== undefined){
      this.getEventSlots(eventId);
      this.getAbstractSubmissions(startYear);
      this.getInvitedSpeakerSubmissions(startYear);
    }
  }
  componentDidMount(){
    this._is_Mounted = true;
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    if(sid){
      CheckActiveSession(sid).then(res => {
        if(res.status === true){
          this.getEventData(this.state.id);
          return;
        }else{
          window.location.href="https://" + hostname + "/ecvs-admin/";
          return;
        }
      });
    }
    this.getMembers();
  }
  componentDidUpdate(prevProps) {
    if(this.props.match.params.id !== prevProps.match.params.id && this._is_Mounted) {
      this.setState({
        id: this.props.match.params.id,
        slots: [], 
        //locationData: [],
        streamData: [],
        invited_speaker_submissions: [],
        abstract_submissions: [],
      });
      this.getEventData(this.props.match.params.id);
    }
  }
  componentWillUnmount(){
    this._is_Mounted = false;
    this.abortController.abort();
  }
  render(){
    return(
      <Fragment>
        <Scheduler 
          eventID={this.state.id} 
          event={this.state.event}
          slots={this.state.slots} 
          //locationData={this.state.locationData}
          streamData={this.state.streamData}
          colours={this.colours}
          abstractSubmissions={this.state.abstract_submissions}
          invitedSpeakerSubmissions={this.state.invited_speaker_submissions}
          members={this.state.members}
        />
      </Fragment>
    );
  }
}