import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { TextField, EditButton, DateField } from 'react-admin';

const cardStyle = {
  width: 300,
  minHeight: 300,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top'
};

const EventCards = ({ ids, data, basePath }) => (
  <div style={{ margin: '1em' }}>
  {ids.map(id =>
      <Card key={id} style={cardStyle}>
          <CardHeader
            title={<TextField record={data[id]} source="title" />}
          />
          <CardContent>
            <TextField record={data[id]} source="description" />
          </CardContent>
          <CardContent>
            Start: <DateField record={data[id]} source="start_date" />
          </CardContent>
          <CardContent>
            End: <DateField record={data[id]} source="end_date" />
          </CardContent>
          <CardActions style={{ textAlign: 'right' }}>
              <EditButton basePath={basePath} record={data[id]} />
          </CardActions>
      </Card>
  )}
  </div>
);
EventCards.defaultProps = {
  data: {},
  ids: [],
};

export const EventGrid = (props) => (
  <EventCards 
    {...props}
    ids={[0, 1, 2]} 
    basePath={'/events/edit'}
  />
);