import React, { Fragment } from 'react';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

export default class Stream extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            streamData: props.streamData,
            currentStreamData: props.streamData,
            selectedStreams: this.getStreamIDs(props.streamData),
            addedRoomsSinceConstructor: []
        };
        this._is_Mounted = false;
        this.colours = props.colours;
    }
    componentDidMount(){
      this._is_Mounted = true;
    }
    componentDidUpdate(prevProps){
      const prevStreamData = JSON.stringify(prevProps.streamData);
      const nowStreamData = JSON.stringify(this.props.streamData);
      if((this.props.eventID !== prevProps.eventID || prevStreamData !== nowStreamData) && this._is_Mounted){
        this.setState({
          streamData: this.props.streamData, 
          currentStreamData: this.props.streamData,
          selectedStreams: this.getStreamIDs(this.props.streamData)
        });
      }
    }
    componentWillUnmount(){
      this._is_Mounted = false;
    }

    handleStreamChange = (streams) => {
      const currentStreamData = streams;
      this.props.onStreamChange(currentStreamData);
    }

    onChange(args){
      const currentStreamData = this.state.currentStreamData;
      const addedRoomsSinceConstructor = this.state.addedRoomsSinceConstructor;
      if(args.name === 'select'){
        if(args.itemData){
          if(args.itemData.text !== null){
            const streamText = args.itemData.text;
            const streams = this.state.streamData;
            let stream = streams.find(l => l.text === streamText);
            if(!stream){
              stream = addedRoomsSinceConstructor.find(l => l.text === streamText);
            }
            if(stream){
              let updatedCurrentStreamData = currentStreamData.concat(stream);
              this.handleStreamChange(updatedCurrentStreamData);
              this.setState({
                currentStreamData: updatedCurrentStreamData,
                selectedStreams: this.getStreamIDs(updatedCurrentStreamData)
              });
            }else{
              let newStreamID = (streams.length + addedRoomsSinceConstructor.length) + 1;
              let colour = this.colours[newStreamID % this.colours.length];
              const newStream = {
                text: streamText,
                id: newStreamID,
                color: colour
              };
              let updatedCurrentStreamData = currentStreamData.concat([newStream]);
              let updatedAddedRoomsSinceConstructor = addedRoomsSinceConstructor.concat([newStream]);
              let selectedStreams = this.getStreamIDs(currentStreamData);
              this.handleStreamChange(updatedCurrentStreamData);
              this.setState({
                currentStreamData: updatedCurrentStreamData,
                selectedStreams: selectedStreams,
                addedRoomsSinceConstructor: updatedAddedRoomsSinceConstructor
              });
            }
          }
        }
      }
      if(args.name === 'removed'){
        if(args.itemData !== null){
          let updatedCurrentStreamData = currentStreamData.filter(l => l.text !== args.itemData.text);
          this.handleStreamChange(updatedCurrentStreamData);
          this.setState({
            currentStreamData: updatedCurrentStreamData,
            selectedStreams: this.getStreamIDs(updatedCurrentStreamData)
          });
        }
      }
    }

    getStreamIDs(streams){
      if(streams){
        return streams.map((stream) => {
          return stream.text;
        });
      }
      return [];
    }

    render(){
        return (
            <Fragment>
                <MultiSelectComponent 
                  ref={t => this.streamObj = t}
                  id="Stream" 
                  width="85%"
                  dataSource={this.state.streamData}
                  fields={{ text: 'text', value: 'text' }} 
                  placeholder="Select streams" 
                  mode="Box" 
                  allowCustomValue={true}
                  select={this.onChange.bind(this)}
                  removed={this.onChange.bind(this)}
                  value={this.state.selectedStreams}
                  ignoreCase={true}
                />
            </Fragment>
        );
    }
}