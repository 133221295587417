import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { ScheduleComponent, Week, TimelineViews, ResourcesDirective, ResourceDirective, Inject, ViewsDirective, ViewDirective, DragAndDrop, Resize } from '@syncfusion/ej2-react-schedule';
import { extend, createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { showNotification } from 'react-admin';
import Speakers from './Speakers';
//import Location from './Location';
import Stream from './Stream';
import EditEventButton from '../events/EditEventButton';
import '../layout/custom.css';
import EditStreamsButton from '../streams/EditStreamsButton';
import EditSessionsButton from '../events/EditSessionsButton';

const LocationSession = (props) => {
  let divider = ' : ';
  let className = 'location_stream';
  if(!props.location || !props.session){
    divider = '';
  }
  if(!props.location && !props.session){
    className = '';
  }
  return (
    <Fragment>
      <span className={className}>{props.location}{divider}{props.session}</span>
    </Fragment>
  );
}

class Scheduler extends React.Component {
  constructor(props) {
    super(props);
    //var locationData = props.locationData.sort(this.sortAlpha);
    var streamData = props.streamData.sort(this.sortAlpha);
    this.state = {
      event: props.event || {},
      data: props.slots || [],
      //locationData: locationData || [],
      streamData: streamData || [],
      //currentLocationData: locationData || [],
      currentStreamData: streamData || [],
      eventID: props.eventID || null,
    };
    this.eventSet = false;
    this.dataSet = false;
    //this.locationData = false;
    this.streamData = false;
    this.abstractSubmissions = [];
    this.invitedSpeakerSubmissions = [];
    this.members = [];
    this.nextSlotID = 1;
    this.createSlotStatus = null;
  }
  componentDidUpdate(prevProps){
    const event = this.props.event;
    const slots = this.props.slots;
    //var locationData = this.props.locationData;
    var streamData = this.props.streamData;
    const abstractSubmissions = this.props.abstractSubmissions;
    const invitedSpeakerSubmissions = this.props.invitedSpeakerSubmissions;
    const members = this.props.members;
    if(prevProps.eventID !== this.props.eventID){
      this.eventSet = false;
      this.dataSet = false;
      //this.locationData = false;
      this.streamData = false;
      this.abstractSubmissions = [];
      this.invitedSpeakerSubmissions = [];
      this.setState({
        event: {},
        data: [],
        eventID: this.props.eventID,
        //locationData: [],
        streamData: [],
        //currentLocationData: [],
        currentStreamData: [],
      });
    }
    if(!this.eventSet && prevProps.event.ID !== this.props.event.ID){
      this.setState({
        event: event,
      });
      this.eventSet = true;
      this.nextSlotID = event.NextAvailableSlotID;
      this.scheduleObj.selectedDate = new Date(Date.parse(event.StartDate));
    }
    if(!this.dataSet && slots !== null && slots.length !== 0){
      this.setState({
        data: extend([], slots, null, true), 
      });
      this.dataSet = true;
      //this.nextSlotID = Math.max(...slots.map(o => parseInt(o.ID)), 0) + 1;
    }
    /*if(!this.locationData && locationData !== null && locationData.length !== 0){
      locationData = locationData.sort(this.sortAlpha);
      this.setState({
        locationData: locationData, 
        currentLocationData: locationData,
      });
      this.locationData = true;
    }*/
    if(!this.streamData && streamData !== null && streamData.length !== 0){
      streamData = streamData.sort(this.sortAlpha);
      this.setState({
        streamData: streamData, 
        currentStreamData: streamData,
      });
      this.streamData = true;
    }
    if(abstractSubmissions !== null && abstractSubmissions.length !== 0 && this.abstractSubmissions.length === 0){
      this.abstractSubmissions = abstractSubmissions;
      this.abstractSubmissions.unshift({
        ID: null,
        AbstractTitle: '',
        SlotId: null,
        SubmittedBy: '',
        SubmittedByAndAbstractTitle: ''
      });
    }
    if(invitedSpeakerSubmissions !== null && invitedSpeakerSubmissions.length !== 0 && this.invitedSpeakerSubmissions.length === 0){
      this.invitedSpeakerSubmissions = invitedSpeakerSubmissions;
      this.invitedSpeakerSubmissions.unshift({
        ID: null,
        AbstractTitle: '',
        SlotId: null,
        SubmittedBy: '',
        SubmittedByAndAbstractTitle: ''
      });
    }
    if(members !== null && members.length !== 0 && this.members.length === 0){
      this.members = members;
    }
  }
  sortAlpha( a, b ) {
    if ( a.text < b.text ){
      return -1;
    }
    if ( a.text > b.text ){
      return 1;
    }
    return 0;
  }
  eventTemplate(props){
    //console.log(props);
    if(props.Subject){
      props.Title = props.Subject;
    }

    let speakers = null;
    if(props.SpeakerName_0 !== null){
      var speakersArr = this.formatSpeakers(props);
      if(speakersArr){
        speakers = "";
        for(let i=0; i<speakersArr.length; i++){
          let member = this.members.find(member => member.ID === speakersArr[i].id);
          if(member !== undefined){
            if(speakers > ""){
              speakers += ", ";
            }
            speakers += member.FullName;
          }
        }
      }
    }

    let titleSpeakerDivider = ' | '; 
    if(!props.Title || !speakers){
      titleSpeakerDivider = '';
    }

    return (
      <div className="template-wrap" title={props.Title} style={{ background: props.SecondaryColor }}>
        <div className="resource" style={{ background: props.PrimaryColor }}><LocationSession location={props.Location} session={props.Session}/></div>
        <div className="subject" style={{ background: props.PrimaryColor }}>{speakers}{titleSpeakerDivider}{props.Title}</div>
      </div>
    );
  }
  editorTemplate(props) {
    const members = this.members;
    const abstractSubmissions = this.abstractSubmissions;
    let unassignedAbstractSubmissions = [];
    const invitedSpeakerSubmissions = this.invitedSpeakerSubmissions;
    let unassignedInvitedSpeakerSubmissions = [];
    if(abstractSubmissions){
      let currentAbstract = abstractSubmissions.find(submission => submission.SlotId === props.ID);
      unassignedAbstractSubmissions = abstractSubmissions.filter(submission => submission.SlotId === null);
      if(currentAbstract){
        props.AbstractSubmission = currentAbstract.ID;
        unassignedAbstractSubmissions.push(currentAbstract);
      }
    }
    if(invitedSpeakerSubmissions){
      let currentAbstract = invitedSpeakerSubmissions.find(submission => submission.SlotId === props.ID);
      unassignedInvitedSpeakerSubmissions = invitedSpeakerSubmissions.filter(submission => submission.SlotId === null);
      if(currentAbstract){
        props.InvitedSpeakerSubmission = currentAbstract.ID;
        unassignedInvitedSpeakerSubmissions.push(currentAbstract);
      }
    }
    if(props.Subject !== undefined && props.Subject > ''){
      props.Title = props.Subject;
    }
    let minDate = null;
    if(props.StartTime){
      minDate = new Date(props.StartTime.getTime() + 5*60000);
    }
    return (props !== undefined ? <table className="custom-event-editor" style={{ width: '100%', cellpadding: '5' }}><tbody id="templateTableBody">
      <tr>
        <td className="e-textlabel">Title</td>
        <td colSpan="3">
          <TextBoxComponent 
            id="Title" 
            className="e-field e-input" 
            type="text" 
            name="Title" 
            value={props.Title || ""} 
          />
        </td>
      </tr>
      {/*<tr>
        <td className="e-textlabel">Status</td>
        <td colSpan="3">
          <DropDownListComponent 
            id="SlotType" 
            placeholder='Choose status' 
            data-name="SlotType" 
            className="e-field" 
            style={{ width: '100%' }} 
            dataSource={['Draft', 'Confirmed']} 
            value={props.SlotType || null}
          ></DropDownListComponent>
        </td>
      </tr>*/}
      <tr>
        <td className="e-textlabel">Description</td>
        <td colSpan="3">
          <textarea 
            id="Description" 
            className="e-field e-input" 
            name="Description" 
            rows={3} 
            cols={50} 
            style={{ width: '100%', height: '60px !important', resize: 'vertical' }}
          ></textarea>
        </td>
      </tr>
      <tr id="insertStream">
        <td className="e-textlabel">Location</td>
        <td colSpan="3">
          <TextBoxComponent 
            className="e-field e-input" 
            type="text" 
            name="Location" 
            value={props.Location || ""}
          />
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">Session</td>
        <td colSpan="3">
          <TextBoxComponent 
            id="Session" 
            className="e-field e-input" 
            type="text" 
            name="Session" 
            value={props.Session || ""}
          />
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">Sub-Session</td>
        <td colSpan="3">
          <TextBoxComponent 
            id="Sub_Session" 
            className="e-field e-input" 
            type="text" 
            name="Sub_Session" 
            value={props.Sub_Session || ""}
          />
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">From</td>
        <td colSpan="3">
          <DateTimePickerComponent 
            ref={r => this.fromDate = r}
            format='dd/MM/yy hh:mm a' 
            id="StartTime" 
            data-name="StartTime" 
            value={new Date(props.startTime || props.StartTime)} 
            className="e-field" 
            step="5"
            allowEdit={false}
            showClearButton={false}
            strictMode={true}
            change={this.onDateChange.bind(this)}
          ></DateTimePickerComponent>
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">To</td>
        <td colSpan="3">
          <DateTimePickerComponent 
            ref={r => this.toDate = r}
            format='dd/MM/yy hh:mm a' 
            id="EndTime" 
            data-name="EndTime" 
            value={new Date(props.endTime || props.EndTime)} 
            className="e-field" 
            step="5"
            min={minDate}
            allowEdit={false}
            showClearButton={false}
            strictMode={true}
            change={this.onDateChange.bind(this)}
          ></DateTimePickerComponent>
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">Chair</td>
        <td colSpan="3">
          <TextBoxComponent 
            id="Chair" 
            className="e-field e-input" 
            type="text" 
            name="Chair" 
            value={props.Chair || ""}
          />
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">Discussant</td>
        <td colSpan="3">
          <TextBoxComponent 
            id="Discussant" 
            className="e-field e-input" 
            type="text" 
            name="Discussant" 
            value={props.Discussant || ""}
          />
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">Abstract Submission</td>
        <td colSpan="3">
          <DropDownListComponent 
            id="AbstractSubmission" 
            placeholder='Choose submission' 
            data-name="AbstractSubmission" 
            className="e-field" 
            style={{ width: '100%' }} 
            dataSource={unassignedAbstractSubmissions} 
            fields={{text: 'SubmittedByAndAbstractTitle', value: 'ID'}} 
            sortOrder="Ascending"
            value={props.AbstractSubmission || null}
          ></DropDownListComponent>
        </td>
      </tr>
      <tr>
        <td className="e-textlabel">Invited Speaker Submission</td>
        <td colSpan="3">
          <DropDownListComponent 
            id="InvitedSpeakerSubmission" 
            placeholder='Choose submission' 
            data-name="InvitedSpeakerSubmission" 
            className="e-field" 
            style={{ width: '100%' }} 
            dataSource={unassignedInvitedSpeakerSubmissions} 
            fields={{text: 'SubmittedByAndAbstractTitle', value: 'ID'}} 
            sortOrder="Ascending"
            value={props.InvitedSpeakerSubmission || null}
          ></DropDownListComponent>
        </td>
      </tr>
      <tr>
        <td colSpan="4">&nbsp;</td>
      </tr>
      <tr>
        <td className="e-textlabel">Speakers</td>
      </tr>
      <Speakers {...props} members={members}/>
      <tr>
        <td className="e-textlabel">Notes</td>
        <td colSpan="3">
          <textarea 
            id="Notes" 
            className="e-field e-input" 
            name="Notes" 
            rows={3} 
            cols={50} 
            style={{ width: '100%', height: '60px !important', resize: 'vertical' }}
          ></textarea>
        </td>
      </tr>
      </tbody></table> : <div></div>
    );
  }
  createEditSlot = (data, slotID = null) => {
    const { showNotification } = this.props;
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    /*const location = this.state.currentLocationData.find(location => location.id === data.LocationID);
    let locationText = '';
    if(location){
      locationText = location.text;
    }*/
    const stream = this.state.currentStreamData.find(stream => stream.id === data.StreamID);
    let streamText = '';
    if(stream){
      streamText = stream.text;
    }
    let url = '';
    let successMessage = '';
    let errorMessage = '';
    if(this.state.eventID !== null){
        if(slotID !== null){
          url = 'https://' + hostname + '/api/createSlot.php?s=' + sid + '&event_id=' + this.state.eventID + '&slot_id=' + slotID;
          successMessage = 'Slot updated';
          errorMessage = 'Unable to update slot';
        }else{
          url = 'https://' + hostname + '/api/createSlot.php?s=' + sid + '&event_id=' + this.state.eventID;
          successMessage = 'Slot created';
          errorMessage = 'Unable to create slot';
        }
    }
    var tzoffset = (data.StartTime).getTimezoneOffset() * 60000; //offset in milliseconds
    var startDateTime = (new Date(data.StartTime - tzoffset)).toISOString().slice(0, -1);
    var endDateTime = (new Date(data.EndTime - tzoffset)).toISOString().slice(0, -1);
    var body = {
      title: data.Title || null,
      description: data.Description || null,
      stream: streamText || null,
      session: data.Session || null,
      sub_session: data.Sub_Session || null,
      location: data.Location || null,
      start_datetime: startDateTime || null,
      end_datetime: endDateTime || null,
      abstract_submission: data.AbstractSubmission || null,
      invited_speaker_submission: data.InvitedSpeakerSubmission || null,
      speakers: data.Speakers || null,
      chair: data.Chair || '',
      discussant: data.Discussant || '',
      notes: data.Notes || null,
    }
    fetch(url, {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }).then((response) => {
        if(response.ok){
            return response.json();
        } else{
            console.log(errorMessage);
            return false;
        }
    }).then((res) => {
        if(res.status !== false){
            showNotification(successMessage, 'info');
            return true;
        } else{
            showNotification(errorMessage, 'warning');
            return false;
        }
    }).catch((e) => {
        //console.log('Exception caught: ' + e);
        showNotification(errorMessage, 'warning');
    });
  }
  deleteSlot = (slotID) => {
    const { showNotification } = this.props;
    const sid = localStorage.getItem('sid');
    const hostname = localStorage.getItem('hostname');
    let url = '';
    let successMessage = '';
    let errorMessage = '';
    if(slotID !== null){
      url = 'https://' + hostname + '/api/deleteSlot.php?s=' + sid + '&slot_id=' + slotID;
      successMessage = 'Slot deleted';
      errorMessage = 'Unable to delete slot';
    }
    fetch(url, {
        method: 'DELETE',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }).then((response) => {
        if(response.ok){
            return response.json();
        } else{
            console.log(errorMessage);
            return false;
        }
    }).then((res) => {
        if(res.status !== false){
            showNotification(successMessage, 'info');
            return true;
        } else{
            showNotification(errorMessage, 'warning');
            return false;
        }
    }).catch((e) => {
        //console.log('Exception caught: ' + e);
        showNotification(errorMessage, 'warning');
    });
  }
  onActionBegin(args){
    console.log(args);
    var props = null;
    var dontSave = false;
    // Set the data array to a generic variable for the event types we need to handle
    if(args.requestType === 'eventCreate' || args.requestType === 'eventChange' || args.requestType === 'eventRemove'){
      if(args.data[0]){
        props = args.data[0];
      }else{
        props = args.data;
      }
    }
    // Get the speakers into an API friendly format
    if(props){
      if(props.SpeakerName_0 !== null){
        props.Speakers = this.formatSpeakers(props);
      }
    }

    if(args.requestType === 'eventCreate'){
      if(!dontSave){
        props.ID = this.nextSlotID.toString();
        this.nextSlotID++;
        if(props.Subject !== null && props.Subject !== undefined && props.Title === undefined){
          props.Title = props.Subject;
        }
        if(props.AbstractSubmission !== null && props.AbstractSubmission !== undefined){
          //Find and update the abstract submission, need to do a post update here too
          let abstractSubmissionIndex = this.abstractSubmissions.findIndex(submission => submission.ID === props.AbstractSubmission);
          this.abstractSubmissions[abstractSubmissionIndex].SlotId = props.ID;
        }
        if(props.InvitedSpeakerSubmission !== null && props.InvitedSpeakerSubmission !== undefined){
          //Find and update the invited speaker submission, need to do a post update here too
          let invitedSpeakerSubmissionIndex = this.invitedSpeakerSubmissions.findIndex(submission => submission.ID === props.InvitedSpeakerSubmission);
          this.invitedSpeakerSubmissions[invitedSpeakerSubmissionIndex].SlotId = props.ID;
        }
        this.createEditSlot(props);
      }
    }
    if(args.requestType === 'eventChange' && props.ID !== undefined){
      if(!dontSave){
        if(props.Title !== null && props.Title !== undefined){
          props.Subject = props.Title;
        }
        //If there is an abstract assigned and this is null or different, then unassign and update the other abstract if applicable
        let currentAbstract = this.abstractSubmissions.find(submission => submission.SlotId === props.ID);
        if(currentAbstract){
          if(currentAbstract.ID !== props.AbstractSubmission){
            // Unset current abstract
            let abstractSubmissionIndex = this.abstractSubmissions.findIndex(submission => submission.ID === currentAbstract.ID);
            this.abstractSubmissions[abstractSubmissionIndex].SlotId = null;
            // Assign new abstract to this slot if not completely unassigned
            if(props.AbstractSubmission !== null && props.AbstractSubmission !== undefined){
              let abstractSubmissionIndex = this.abstractSubmissions.findIndex(submission => submission.ID === props.AbstractSubmission);
              this.abstractSubmissions[abstractSubmissionIndex].SlotId = props.ID;
            }
          }
        }
        if(!currentAbstract && props.AbstractSubmission){
          let abstractSubmissionIndex = this.abstractSubmissions.findIndex(submission => submission.ID === props.AbstractSubmission);
          this.abstractSubmissions[abstractSubmissionIndex].SlotId = props.ID;
        }
        // Now do the same for invited speakers
        currentAbstract = this.invitedSpeakerSubmissions.find(submission => submission.SlotId === props.ID);
        if(currentAbstract){
          if(currentAbstract.ID !== props.InvitedSpeakerSubmission){
            // Unset current abstract
            let invitedSpeakerSubmissionIndex = this.invitedSpeakerSubmissions.findIndex(submission => submission.ID === currentAbstract.ID);
            this.invitedSpeakerSubmissions[invitedSpeakerSubmissionIndex].SlotId = null;
            // Assign new abstract to this slot if not completely unassigned
            if(props.InvitedSpeakerSubmission !== null && props.InvitedSpeakerSubmission !== undefined){
              let invitedSpeakerSubmissionIndex = this.invitedSpeakerSubmissions.findIndex(submission => submission.ID === props.InvitedSpeakerSubmission);
              this.invitedSpeakerSubmissions[invitedSpeakerSubmissionIndex].SlotId = props.ID;
            }
          }
        }
        if(!currentAbstract && props.InvitedSpeakerSubmission){
          let invitedSpeakerSubmissionIndex = this.invitedSpeakerSubmissions.findIndex(submission => submission.ID === props.InvitedSpeakerSubmission);
          this.invitedSpeakerSubmissions[invitedSpeakerSubmissionIndex].SlotId = props.ID;
        }
        this.createEditSlot(props, props.ID);
      }
    }
    if(args.requestType === 'eventRemove' && props.ID !== undefined){
      if(props.AbstractSubmission !== null && props.AbstractSubmission !== undefined){
        let abstractSubmissionIndex = this.abstractSubmissions.findIndex(submission => submission.ID === props.AbstractSubmission);
        this.abstractSubmissions[abstractSubmissionIndex].SlotId = null;
      }
      if(props.InvitedSpeakerSubmission && props.InvitedSpeakerSubmission !== undefined){
        let invitedSpeakerSubmissionIndex = this.invitedSpeakerSubmissions.findIndex(submission => submission.ID === props.InvitedSpeakerSubmission);
        this.invitedSpeakerSubmissions[invitedSpeakerSubmissionIndex].SlotId = null;
      }
      this.deleteSlot(props.ID);
    }
  }
  onActionComplete(args){
    console.log(args);
    if(args.requestType === 'eventChanged' && args.data.ID !== undefined){
      const slotData = this.state.data;
      for(let x = 0; x < slotData.length; x++){
        if(slotData[x].ID === args.data.ID){
          slotData[x] = args.data;
          break;
        }
      }
      return slotData;
    }
    if(args.requestType === 'eventRemoved'  && args.data[0].ID !== undefined){
      const slotData = this.state.data;
      for(let x = 0; x < slotData.length; x++){
        if(slotData[x].ID === args.data[0].ID){
          slotData.splice(x, 1);
          break;
        }
      }
      return slotData;
    }
  }
  onActionFailure(args){
    console.log(args);
  }
  onPopupOpen(args) {
    console.log(args);
    if(args.type === 'QuickInfo'){
      const title = args.element.querySelector('.e-subject');
      if(title){
        if(args.data.Title === undefined){
          args.data.Title = '';
        }
        if(args.data.Subject !== undefined && args.data.Subject !== ''){
          args.data.Title = args.data.Subject;
        }
        title.innerText = args.data.Title;
        title.title = args.data.Title;
      }
      const resources = args.element.querySelector('.e-resource-details');
      if(resources){
        resources.innerText = '';
        let speakers = null;
        if(args.data.SpeakerName_0 !== null){
          var speakersArr = this.formatSpeakers(args.data);
          if(speakersArr){
            speakers = "";
            for(let i=0; i<speakersArr.length; i++){
              let member = this.members.find(member => member.ID === speakersArr[i].id);
              if(member.FullName){
                if(speakers > ""){
                  speakers += ", ";
                }
                speakers += member.FullName;
              }
            }
            if(speakers){
              resources.innerText = speakers;
            }
          }
        }
        if(!resources.innerText){
          const resources = args.element.querySelector('.e-resource');
          if(resources){
            resources.style.display = 'none';
          }
        }
      }
    }
    if(args.type === 'Editor'){
      if (!args.element.querySelector('.custom-field-row')) {
        // Insert the row to simulate setting the stream else the editor will fall over on save
        let tableRow = createElement('tr');
        tableRow.style.display = 'none';
        let parent = args.element.querySelector('#templateTableBody');
        let reference = args.element.querySelector('#insertStream');
        parent.insertBefore(tableRow, reference);
        let labelCell = createElement('td', { className: 'e-textlabel' } );
        labelCell.innerText = 'Stream';
        let inputCell = createElement('td');
        let inputEle = createElement('input', {
            className: 'e-field', attrs: { name: 'StreamID' }
        });
        tableRow.appendChild(labelCell);
        tableRow.appendChild(inputCell);
        inputCell.appendChild(inputEle);
        let drowDownList = new DropDownList({
            dataSource: this.state.currentStreamData,
            fields: { text: 'text', value: 'id' },
            value: args.data.StreamID,
        });
        drowDownList.appendTo(inputEle);
      }
      const title = args.element.querySelector('#Title');
      if(title){
        if(args.data.Subject === undefined){
          args.data.Subject = '';
        }
        title.value = args.data.Subject;
      }
    }
    if(args.type === 'DeleteAlert'){
      // const title = args.element.querySelector('#QuickDialog_title');
      // if(title){
      //   title.innerText = args.data.event.Title;
      // }
      const message = args.element.querySelector('#QuickDialog_dialog-content');
      if(message){
        message.innerText = 'Are you sure you want to delete this slot?'
      }
    }
  }
  /*componentDidMount(){
    if(this.state.event.StartDate){
      this.scheduleObj.selectedDate = new Date(Date.parse(this.state.event.StartDate.replace('-','/','g')));
    }else{
      this.scheduleObj.selectedDate = new Date().getFullYear();
    }
  }*/
  onDateChange = (props) => {
    if(props.event !== null){
      console.log('date change');
      //console.log(props);
      //console.log(this.fromDate.value);
      //console.log(this.toDate.value);
      if(this.fromDate.value){
        this.toDate.min = new Date(this.fromDate.value.getTime() + 5*60000);
      }
      //this.fromDate.value = new Date('2020/01/03 12:00:00');
    }
  }
  locationDataChange = (locationData) => {
    this.setState({currentLocationData: locationData});
  }
  streamDataChange = (streamData) => {
    this.setState({currentStreamData: streamData});
  }
  formatSpeakers = (slotData) => {
    if(slotData.SpeakerName_0 !== null){
      var speakers = [];
      var speakerExists = true;
      let x = 0;
      while(speakerExists === true){
        let speakerPropertyName = 'SpeakerName_' + x;
        let speakerPropertyType = 'SpeakerType_' + x;
        let speakerPropertyNotes = 'SpeakerNotes_' + x;
        let speaker = {};
        if(slotData[speakerPropertyName] !== undefined){
          speaker.id = slotData[speakerPropertyName];
          if(slotData[speakerPropertyType] !== undefined){
            speaker.type = slotData[speakerPropertyType];
          }else{
            speaker.type = null;
          }
          if(slotData[speakerPropertyNotes] !== undefined){
            speaker.notes = slotData[speakerPropertyNotes];
          }else{
            speaker.notes = null;
          }
          speakers.push(speaker);
          x++;
        }else{
          speakerExists = false;
        }
      }
      return speakers;
    }else{
      return null;
    }
  }
  render(){
    const eventID = this.state.eventID;
    return (
      <Fragment>
        <div className="shedule_header">
          {/*<Location 
            locationData={this.state.locationData} 
            onLocationChange={this.locationDataChange} 
            eventID={eventID}
            colours={this.props.colours}
          />*/}
          <Stream 
            streamData={this.state.streamData} 
            onStreamChange={this.streamDataChange} 
            eventID={eventID}
            colours={this.props.colours}
          />
          <EditEventButton label="Edit Event" eventID={eventID}/>
          <EditStreamsButton label="Edit Streams" eventID={eventID} />
          {/* <EditSessionsButton label="Edit Sessions" eventID={eventID} /> */}
        </div>
        <ScheduleComponent
          ref={t => this.scheduleObj = t}
          width='100%' 
          height='800px'
          eventSettings={{ 
            dataSource: this.state.data, 
            template: this.eventTemplate.bind(this),
          }} 
          timeScale={{ 
            enable: true, 
            interval: 10, 
            slotCount: 2 }
          }
          workHours={{
            highlight: false, 
            start: '08:00', 
            end: '20:00'
          }}
          editorTemplate={this.editorTemplate.bind(this)}
          showQuickInfo={true} 
          allowDragAndDrop={true}
          rowAutoHeight={true}
          cssClass='schedule-cell-dimension'
          currentView='TimelineDay'
          group={{ resources: ['Streams'] }}
          actionBegin={this.onActionBegin.bind(this)}
          actionComplete={this.onActionComplete.bind(this)}
          popupOpen={this.onPopupOpen.bind(this)}
        >
          <ResourcesDirective>
            <ResourceDirective 
              field='StreamID' 
              title='Stream' 
              name='Streams' 
              allowMultiple={false} 
              dataSource={this.state.currentStreamData} 
              textField='text' 
              idField='id' 
              colorField='color'
            />
          </ResourcesDirective>
          <ViewsDirective>
            <ViewDirective option='Week' startHour='07:00' endHour='20:00'/>
            <ViewDirective option='TimelineDay' startHour='08:00' endHour='22:00'/>
          </ViewsDirective>
          <Inject services={[Week, TimelineViews, DragAndDrop, Resize]}/>
        </ScheduleComponent>
      </Fragment>
    );
  }
}

export default connect(null, {
  showNotification
})(withRouter(Scheduler));