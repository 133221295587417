import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { MenuItemLink, Responsive, getResources } from 'react-admin';
import  SubMenu  from './PlannerSubMenu';
import Event from '@material-ui/icons/Event';
import Add from '@material-ui/icons/Add';
import Dashboard from '@material-ui/icons/Dashboard';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    listItem: {
        paddingLeft: '1rem',
    },
    listItemText: {
        paddingLeft: 2,
        fontSize: '1rem',
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
};

class PlannerMenu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            events: null,
            menuEvents: true,
        };
    }

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    getEvents(){
        const SID = localStorage.getItem('sid');
        const hostname = localStorage.getItem('hostname');
        fetch('https://' + hostname + '/api/getEvents.php?s=' + SID, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          if(response.ok){
            return response.json();
          } else{
            console.log('Unable to fetch events');
          }
        })
        .then(res => {
            let events = this.parseJSONStringArray(res.result);
            return events;
        }).then((events) => {
            if(events){
                this.setState({events: events});
            }
        });
    }
    parseJSONStringArray(JSONStringArray){
        return (JSONStringArray !== null && JSONStringArray !== undefined ? JSONStringArray.map((JSONString) => JSON.parse(JSONString)) : null);
    }
    componentDidMount(){
        this.getEvents();
    }
    componentDidUpdate(prevProps){
        if(prevProps.location.pathname !== this.props.location.pathname){
            this.getEvents();
        }
    }
    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout, classes } = this.props;
        return(
            <div>
                {' '}
                <MenuItemLink to="/" primaryText="Dashboard" onClick={onMenuClick} leftIcon={<Dashboard />}/>
                <SubMenu
                    handleToggle={() => this.handleToggle('menuEvents')}
                    isOpen={this.state.menuEvents}
                    sidebarIsOpen={open}
                    name="Events"
                    icon={<Event />}
                    classes={classes}
                >
                    {this.state.events && this.state.events.map((event) => {
                        return <MenuItemLink key={ event.ID.toString() } to={"/event/" + event.ID} primaryText={event.Location} onClick={onMenuClick} leftIcon={<Event />}/>
                    })}
                    <MenuItemLink to="/events/create" primaryText="Create Event" onClick={onMenuClick} leftIcon={<Add />}/>
                </SubMenu>
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(PlannerMenu)));