import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

class EditEventButton extends Component {
    constructor(props){
        super(props);
        if(props.eventID !== undefined && props.eventID !== null){
            this.eventID = props.eventID;
        }else{
            this.eventID = null;
        }
    }
    componentDidUpdate(prevProps){
        if(prevProps.eventID !== this.props.eventID){
            this.eventID = this.props.eventID;
        }
    }
    onClickHandler = (props) => {
        this.props.history.push("/events/edit/" + this.eventID);
    }
    render(){
        return(
            <Fragment>
                <Button 
                    onClick={this.onClickHandler}
                    className="edit_button"
                ><EditIcon />&nbsp;{this.props.label}</Button>
            </Fragment>
        );
    }
}

export default withRouter(EditEventButton);